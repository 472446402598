import React from "react"
import StandardButton from "../../shared/standardButton"
import { useDocumentBuilderApiContext } from "../../../contexts/documentBuilderContext"

const ActionsNav = () => {
  const { saveDocument, toggleModal } = useDocumentBuilderApiContext()

  return (
    <div className="flex justify-end w-full sticky top-0 z-10 shadow-lg bg-white">
      <button onClick={ () => toggleModal('definitions', true) } className="btn btn-primary font-semibold my-4">
        3rd Party Definitions
      </button>
      <StandardButton buttonText="Save Document" onClickHandler={ saveDocument }/>
    </div>
  )
}

export default ActionsNav
