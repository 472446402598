import React, { useMemo } from "react"
import { tableStyles } from "../../../utilities/stylesHelpers"
import { classNames } from "../../../utilities/utils"
import { useTimesheetContext } from "../../../contexts/timesheetContext"
import { formatDateString, addDays } from "../../../utilities/dateHelpers"

const smallHeaderStyles = classNames(tableStyles.th, tableStyles.thSm)

const TimesheetTableHeader = () => {
  const { weekStart } = useTimesheetContext()
  const weekDates = useMemo(() => {
    return Array.from({ length: 7 }, (_, i) => addDays(weekStart, i))
  }, [weekStart])

  return (
    <thead className={tableStyles.tableHeaderRow}>
      <tr className={tableStyles.tableHeaderRow}>
        <th className={smallHeaderStyles}>
          <div className={tableStyles.thContent}>
            Job No
          </div>
        </th>
        <th className={smallHeaderStyles}>
          <div className={tableStyles.thContent}>
            Historical Job No
          </div>
        </th>
        <th className={smallHeaderStyles}>
          <div className={tableStyles.thContent}>
            Project
          </div>
        </th>
        <th className={smallHeaderStyles}>
          <div className={tableStyles.thContent}>
            Task
          </div>
        </th>
        {weekDates.map((date, index) => (
          <th className={smallHeaderStyles} key={index}>
            <div className={tableStyles.thContent}>
              {formatDateString(date, { weekday: 'long', month: 'long' })}
            </div>
          </th>
        ))}
        <th className={classNames(tableStyles.th, tableStyles.thXl)}>
          <div className={tableStyles.thContent}>
            Summary of Activities
          </div>
        </th>
      </tr>
    </thead>
  )
}

export default TimesheetTableHeader
