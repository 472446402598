import React, { useCallback, useMemo } from "react"
import Select from "react-select"
import { useDocumentBuilderApiContext } from "../../../contexts/documentBuilderContext"
import Content from "./content"
import { optionize } from "../../../utilities/utils"
import { componentSwitch } from "../../../utilities/documentBuilderHelpers"

const Section = ({ section }) => {
  const { updateSectionState } = useDocumentBuilderApiContext()

  const layoutOptions = useMemo(() => {
    return optionize('name', 'id')(section.sectionLayouts)
  }, [section])

  const currentLayout = useMemo(() => {
    return section.sectionLayouts.find(layout => layout.id === section.currentSectionLayout)
  }, [section])

  const currentOption = useMemo(() => currentLayout && { label: currentLayout.name }, [currentLayout])

  const SectionLayoutComponent = useMemo(() => componentSwitch(currentLayout?.type), [currentLayout])

  const handleChange = useCallback(chosenLayout => {
    updateSectionState(section.id, 'currentSectionLayout', chosenLayout.value)
  }, [section])

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between w-full">
        <h2 className="text-2xl font-bold">{ section.title }</h2>
        <Select
          className={ "capitalize w-56" }
          placeholder={ "No Section Layouts" }
          options={ layoutOptions }
          value={ currentOption }
          isDisabled={ layoutOptions.length < 2 }
          onChange={ (valueObject) => handleChange(valueObject) }
        />
      </div>
      { currentLayout && <SectionLayoutComponent sectionLayout={ currentLayout } ContentComponent={ Content }/> }
    </div>
  )
}

export default Section
