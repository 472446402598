import React, { useMemo } from "react"
import { useDocumentBuilderContext } from "../../../contexts/documentBuilderContext"
import DateContent from "../content/dateContent"
import Dropdown from "../content/dropdown"
import Simple from "../content/simple"
import StaticContent from "../content/staticContent"
import UserInput from "../content/userInput"

const contentTypes = {
  'Static': StaticContent,
  'User Input': UserInput,
  'Simple': Simple,
  'Date': DateContent,
  'Dropdown': Dropdown
}

const Content = ({ contentId }) => {
  const { contents } = useDocumentBuilderContext()
  const content = useMemo(() => contents[contentId], [contentId, contents])
  const ContentComponent = useMemo(() => contentTypes[content?.type], [content])

  return <ContentComponent contentId={ contentId } content={ content }/>
}

export default Content
