import React, { useState } from 'react'
import { useAiDocumentAPI } from '../../../contexts/aiDocumentContext'
import { useAiDocumentFilterContext } from '../../../contexts/aiDocumentFilterContext'
import DocumentView from './documentView'
import BaseHoverIcon from "../../tableTemplates/icons/hoverIcon"
import { faInfo, faTrashCan, faPencil, faCheckDouble, faClipboard } from "@fortawesome/free-solid-svg-icons"
import ToolTip from '../../tableTemplates/icons/toolTip'
import { aiHeaders } from '../../../constants/headers'
import { docTableStyles } from "../../../utilities/stylesHelpers"
import { classNames } from "../../../utilities/utils"
import DocumentFilters from './documentFilters'

const DocumentGrid = () => {
  const { toggleModal, selectAll, promptAll, deleteAll } = useAiDocumentAPI()
  const { filteredDocuments } = useAiDocumentFilterContext()
  const [toolTip, setToolTip] = useState(false)
  const [toolTipMessage, setToolTipMessage] = useState('')
  const toggleFunc = (state) => setToolTip(state)
  const updateFunc = (message) => setToolTipMessage(message)

  return (
    <>
      <DocumentFilters />
      <ToolTip toolTip={toolTip} toolTipMessage={toolTipMessage} />
      <table className={classNames(docTableStyles.table, 'w-full')}>
        <thead className={docTableStyles.tableHeaderRow}>
          <tr className={docTableStyles.tableHeaderRow}>
            {aiHeaders.map((header, index) => (
              <th key={index} className={classNames(docTableStyles.th)}>
                <div className={docTableStyles.thContent}>
                  <strong>{header.title}</strong>
                  <BaseHoverIcon icon={faInfo} message={header.description} toggleFunc={toggleFunc}
                    updateFunc={updateFunc} />
                </div>
              </th>
            ))}
            <th className={classNames(docTableStyles.th)}>
              <div className={classNames(docTableStyles.thContent, 'w-full justify-center')}>
                <BaseHoverIcon
                  icon={faCheckDouble}
                  message={"Select All"}
                  toggleFunc={toggleFunc}
                  updateFunc={updateFunc}
                  onClickHandler={selectAll}
                />
                <BaseHoverIcon
                  icon={faPencil}
                  message={"Begin processing json into CSV's"}
                  toggleFunc={toggleFunc}
                  updateFunc={updateFunc}
                  onClickHandler={promptAll}
                />
                <BaseHoverIcon
                  icon={faClipboard}
                  message={"Update Status"}
                  toggleFunc={toggleFunc}
                  updateFunc={updateFunc}
                  onClickHandler={() => toggleModal('status')}
                />
                <BaseHoverIcon
                  icon={faTrashCan}
                  message={"Delete all selected documents"}
                  toggleFunc={toggleFunc}
                  updateFunc={updateFunc}
                  onClickHandler={deleteAll}
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredDocuments.map((document) => (
            <DocumentView key={document.id} document={document} />
          ))}
        </tbody>
      </table>
      <button
        className='border border-gray-300 p-2 btn btn-primary cursor-pointer text-xl font-bold w-56 text-center my-7 col-start-2'
        onClick={() => toggleModal('upload')}
      >
        Upload
      </button>
    </>
  )
}

export default DocumentGrid
