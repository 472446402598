import React from 'react'
import { useDocumentBuilderApiContext, useDocumentBuilderContext } from "../../../contexts/documentBuilderContext"
import ModalWrapper from "../../modal/modalWrapper"
import DataSource from "./dataSource"

const DefinitionsModal = () => {
  const { dataSources } = useDocumentBuilderContext()
  const { toggleModal } = useDocumentBuilderApiContext()

  return (
    <ModalWrapper
      heading="Profile Modal"
      closeModal={ () => toggleModal('definitions', false) }
      formName="profile-form"
      submitButtonText='Submit'
      modalContainerClasses="max-h-[90%] my-6 w-7/12"
    >
      <form className="py-8">
        { dataSources.map(dataSource => <DataSource key={ dataSource.id } dataSource={ dataSource }/>) }
      </form>
    </ModalWrapper>
  )
}

export default DefinitionsModal
