import React from 'react'
import ProjectChecklist from "./projectChecklist"
import ChecklistModal from "./checklistModal"
import { useChecklistContext } from "../../contexts/checklistContext"
import ProjectView from "../../layouts/tablePage/projectView"
import NewChecklistButton from './checklistButtons/newChecklistButton'
import HideAllChecklistButton from './checklistButtons/hideAllChecklistButton'
import ChecklistFilters from './checklistFilters'
import { useChecklistFilterContext } from '../../contexts/checklistFilterContext'
import InfoModal from './checklistModal/infoModal'
import ChecklistToolTip from './checklistToolTip'
import CollapsedStyleButton from './checklistButtons/collapsedStyleButton'

function ProjectChecklistView({ routes }) {
  const { template, projectChecklists, project } = useChecklistContext()
  const { checklistIds } = useChecklistFilterContext()

  const filteredProjectChecklists = projectChecklists.filter(checklist => checklistIds.flat().includes(checklist.id))
  const checklists = filteredProjectChecklists.length > 0 ? filteredProjectChecklists : projectChecklists
  const projectChecklistIds = checklists.map((projectChecklist) => projectChecklist.id)
  
  const projectChecklistComponent = checklists?.map((projectChecklist) => {
    if (projectChecklist.status !== 'Deactivate_Checklist') {
      return <ProjectChecklist key={projectChecklist.id} projectChecklist={projectChecklist} />
    }
    return null
  })

  return (
    <>
      <ProjectView routes={ routes }>
        <div className='flex flex-col w-full h-full overflow-scroll'>
          <ChecklistFilters/>
          <div className="m-4 sticky left-4 flex flex-row items-center">
            <h1 className="text-3xl font-bold"> { project?.name }- { template.name }</h1>
            <HideAllChecklistButton projectChecklistIds={ projectChecklistIds }/>
            <CollapsedStyleButton/>
          </div>
          { projectChecklistComponent }
          <div className="m-4 sticky left-4">
            <NewChecklistButton projectId={ project?.id } checklistSlug={ template?.slug }/>
          </div>
          <ChecklistModal/>
          <InfoModal/>
          <ChecklistToolTip/>
        </div>
      </ProjectView>
    </>
)
}

export default ProjectChecklistView
