import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useAnalyticExportApi } from '../../../contexts/analyticExportContext'
import { openInNewTab } from '../../../utilities/helpers'

const AnalyticExportRow = ({ analyticExport }) => {
  const { deleteAnalyticExport } = useAnalyticExportApi()
  const lot_numbers = useMemo(() => {
    return analyticExport.sample_collections?.map((sample_collection) => sample_collection.lot_number).join(', ')
  }, [analyticExport])

  const guidelines = useMemo(() => {
    return analyticExport.guidelines?.map((guideline) => guideline.name).join(', ')
  }, [analyticExport])

  const { projectId } = useParams()
  return (<>
    <div className='p-2 text-center border border-gray-300'> { analyticExport?.updated_at }</div>
    <div className='p-2 text-center border border-gray-300'> { lot_numbers }</div>
    <div className='p-2 text-center border border-gray-300'> { guidelines }</div>
    <div className='p-2 text-center border border-gray-300'>
      <button
        className='btn btn-orange cursor-pointer text-center w-fit h-fit font-semibold'
        onClick={ () => openInNewTab(`/projects/${ projectId }/analytic-exports/${ analyticExport.id }`) }>
        Review
      </button>
    </div>
    <div className='p-2 text-center border border-gray-300'>N/A</div>
    <div className='p-2 text-center border border-gray-300'>
      <button
        className='btn btn-danger cursor-pointer text-center w-fit h-fit font-semibold'
        onClick={ () => deleteAnalyticExport(analyticExport.id) }
      >
        Delete
      </button>
    </div>
  </>)
}

export default AnalyticExportRow
