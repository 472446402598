import React from 'react'
import { useTestResultsContext } from '../../../contexts/testResultsContext'
import { tableStyles } from '../../../utilities/stylesHelpers'
import GuidelineRow from './guidelineRow'
import TableHeader from './tableHeader'

const TableHeaderRows = () => {
  const blanks = Array.from({ length: 6 }, (_, i) => i)
  const { activeGuidelines, units, chemicals } = useTestResultsContext()

  return (
    <thead className={tableStyles.tableHeaderRow}>
      <tr className={tableStyles.tableHeaderRow}>
        <TableHeader content={''} style={`${tableStyles.thBase} bg-yellow-200`} />
        <TableHeader content={'Background'} />
        {blanks.map((index) => (
          <TableHeader key={`${index}-blank-chemical`} content={''} />
        ))}
        {chemicals.map((chemical, index) => (
          <TableHeader key={`${index}-chemical`} content={chemical.name} />
        ))}
      </tr>
      <tr className={tableStyles.tableHeaderRow}>
        <TableHeader content={''} style={`${tableStyles.thBase} bg-red-200`} />
        <TableHeader content={'Does not meet Requirment'} />
        {blanks.map((index) => (
          <TableHeader key={`${index}-blank-unit`} content={''} />
        ))}
        {units.map((unit, index) => (
          <TableHeader key={`${index}-unit`} content={unit.unit} />
        ))}
      </tr>
      {activeGuidelines.map((guideline) => (
        <GuidelineRow key={guideline.id} guideline={guideline} />
      ))}
    </thead>
  )
}

export default TableHeaderRows
