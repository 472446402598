import React, { useCallback, useMemo, useState } from 'react'
import { useAiDocumentAPI, useAiDocumentContext } from '../../../contexts/aiDocumentContext'
import { byteToMB, openInNewTab } from '../../../utilities/helpers'
import LoadingSpinner from '../../shared/loadingSpinner'
import AnimatedCheckbox from "../../shared/animatedCheckbox"
import { classNames } from "../../../utilities/utils"
import { docTableStyles } from "../../../utilities/stylesHelpers"
import { formatDateTimeStr } from "../../../utilities/dateHelpers"

const fileNameParsed = fileName => fileName?.split('_')?.join(' ')

const DocumentView = ({ document }) => {
  const { selectedDocuments } = useAiDocumentContext()
  const {
    selectDocument,
    onDelete,
    onGetBasicSummary,
    openaiUpload,
    promptDocument,
    toggleModal,
    selectOne
  } = useAiDocumentAPI()
  const [isExpanded, setIsExpanded] = useState(false)

  const { loading, status } = useMemo(() => document, [document])
  const isSelected = useMemo(() => selectedDocuments.has(document.id), [selectedDocuments, document])

  const onSelectDocument = useCallback(() => selectDocument(document.id), [document])

  const statusMessage = useMemo(() => {
    switch (status) {
      case 'uploaded':
        return 'Document is awaiting further action'
      case 'processing':
        return "Generating Json"
      case 'prompting':
        return "Generating CSV"
      default:
        return
    }
  }, [status])

  return (
    <>
      <tr className={ docTableStyles.tr }>
        <td className={ classNames(docTableStyles.td) } onClick={ () => setIsExpanded(!isExpanded) }>
          { fileNameParsed(document.file_name) }
        </td>
        <td className={ classNames(docTableStyles.td) } onClick={ () => setIsExpanded(!isExpanded) }>
          <p>{ document.status[0].toUpperCase() + document.status.slice(1) }</p>
          { document?.json_file_path && document.status === 'processing' && <p>Json has not finished generating</p> }
          { loading && <LoadingSpinner size="2xl"/> }
        </td>
        <td className={ classNames(docTableStyles.td) } onClick={ () => setIsExpanded(!isExpanded) }>
          { formatDateTimeStr(document.updated_at, 'yyyy-MM-dd hh:mmaaa') }
        </td>
        <td className={ classNames(docTableStyles.td) } onClick={ () => setIsExpanded(!isExpanded) }>
          <AnimatedCheckbox checked={ !!document?.openai_id }/>
        </td>
        <td className={ classNames(docTableStyles.td) }>
          <div className={ classNames('flex w-full justify-center items-center') }>
            <AnimatedCheckbox onClick={ onSelectDocument } checked={ isSelected }/>
          </div>
        </td>
      </tr>
      { isExpanded && (
        <tr>
          <td className="border-gray-100 border-l-2 bg-white p-4 align-top">
            <p>
              <strong>Size: </strong>{ byteToMB(document.file_size) } MB
              { statusMessage && <><br/><span>{ statusMessage }</span></> }
            </p>
          </td>
          <td colSpan="4" className="border-gray-100 border-l-0 bg-white">
            <div className="w-full p-4 py-8 grid grid-cols-2 items-start">
              <div className="flex flex-col gap-4">
                <div>
                  <p>Summary:</p>
                  <p>{ document.summary || "Summary has not been generated yet." }</p>
                </div>
                { document.errors_status && (
                  <div>
                    <p>Errors:</p>
                    { document.errors_status.map((error, index) => (
                      <p key={ [document.id, index, 'error'].join("-") }>{ error }</p>
                    )) }
                  </div>
                ) }
              </div>
              <div className="w-full flex justify-end items-center gap-4 flex-wrap">
                { status === 'uploaded' && !document?.json_file_path && <button
                  className='btn btn-green cursor-pointer text-center w-fit h-fit font-semibold'
                  onClick={ () => {
                    selectOne(document.id)
                    toggleModal('json')
                  } }
                >
                  Upload Json
                </button> }
                <button
                  className='btn btn-primary truncate cursor-pointer w-fit h-fit font-semibold'
                  onClick={ () => openInNewTab(document.file_path) }
                  rel="noreferrer noopener"
                >
                  Download Original Document
                </button>
                { document?.json_file_path && <a
                  className='btn btn-primary truncate cursor-pointer w-fit h-fit font-semibold'
                  href={ document.json_file_path }
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Download JSON
                </a> }
                { document?.csv_file_path && <a
                  className='btn btn-primary truncate cursor-pointer w-fit h-fit font-semibold'
                  href={ document.csv_file_path }
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Download CSV
                </a> }
                { document?.json_file_path && status === 'ready' && <button
                  className='btn btn-green cursor-pointer'
                  onClick={ () => promptDocument(document.id) }>
                  Generate Table Data CSV
                </button> }
                { !document?.openai_id && <button
                  className='btn btn-green cursor-pointer'
                  onClick={ () => openaiUpload(document.id) }>
                  Upload for AI
                </button> }
                <button
                  className={ `btn btn-green cursor-pointer w-fit h-fit ${ byteToMB(document.file_size) >= 15 ? 'opacity-50 cursor-not-allowed' : '' }` }
                  onClick={ () => onGetBasicSummary(document.id) }
                  disabled={ byteToMB(document.file_size) >= 15 }
                  title={ byteToMB(document.file_size) >= 15 ? "Summary not available for files over 15MB" : "Get Summary" }
                >
                  { !!document?.summary ? "Regenerate Summary" : "Get Summary" }
                </button>
                <button
                  className='btn btn-orange cursor-pointer text-center w-fit h-fit font-semibold'
                  onClick={ () => {
                    selectOne(document.id), toggleModal('status')
                  } }
                >
                  Change Status
                </button>
                <button
                  className='btn btn-danger cursor-pointer text-center w-fit h-fit font-semibold'
                  onClick={ () => onDelete(document.id) }
                >
                  Delete
                </button>
              </div>
            </div>
          </td>
        </tr>
      ) }
    </>
  )
}

export default DocumentView
