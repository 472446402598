import React from "react"
import { useDocumentBuilderContext } from "../../../contexts/documentBuilderContext"
import ProjectLayout from "../../../layouts/projectLayout"
import ActionsNav from "./actionsNav"
import Section from "./section"
import Breadcrumbs from "../breadcrumbs"
import RenderModal from "../../modal/renderModal"
import DefinitionsModal from "../definitionsModal"

const EditDocumentBuilder = () => {
  const { template, sections, modal } = useDocumentBuilderContext()

  return (
    <ProjectLayout
      nestedBreadcrumbs={ <Breadcrumbs/> }
      pageTitle={
        <div className='pl-8 pb-5'>
          <h1 className='text-3xl font-bold'>{ template.name } Builder</h1>
        </div>
      }
    >
      <div className="flex flex-col w-full z-0 text-[16px]">
        <ActionsNav/>
        <div className="flex flex-col w-full p-8 pt-4 gap-4 z-0">
          { sections.map(section => <Section key={ section.id } section={ section }/>) }
        </div>
      </div>
      { modal?.definitions && <RenderModal><DefinitionsModal/></RenderModal> }
    </ProjectLayout>
  )
}

export default EditDocumentBuilder
