import React, { useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import DefinitionInput from "./definitionInput"
import { classNames } from "../../../utilities/utils"

const DataSource = ({ dataSource: { id, name, definitions } }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className='flex flex-col gap-6'>
      <div
        onClick={ () => setExpanded(state => !state) }
        className='w-full flex justify-center cursor-pointer'
      >
        <h3 className='font-semibold text-2xl'>
          { name }&nbsp;
          <FontAwesomeIcon icon={ expanded ? faChevronUp : faChevronDown }/>
        </h3>
      </div>
      <div className={ classNames('grid transition-all', expanded ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]') }>
        <div className='overflow-hidden grid gap-4'>
          { definitions.map(definition => <DefinitionInput key={ definition.id } definition={ definition }/>) }
        </div>
      </div>
    </div>
  )
}

export default DataSource
