import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import { useTestResultsContext } from '../../../../contexts/testResultsContext'
import { inputClass } from "../../../../utilities/stylesHelpers"

const GuidelineSelect = ({ guideline }) => {
  const { guidelines } = useTestResultsContext()
  const [selectedGuideline, setSelectedGuideline] = useState(null)

  const valueOptions = useMemo(() => {
    return guidelines.map(guideline => ({ value: guideline.id, label: guideline.name }))
  }, [guidelines])

  useEffect(() => {
    guideline ?
      setSelectedGuideline({ value: guideline.id, label: guideline.name }) : setSelectedGuideline(null)
  }, [guideline])

  return (
    <label htmlFor="guideline_id" className="flex flex-row items-center justify-start w-full">
      <Select
        name="guideline_id"
        options={valueOptions}
        className={inputClass}
        onChange={setSelectedGuideline}
        value={selectedGuideline}
        isClearable='true'
        placeholder={'Choose a Guideline'}
      />
      <span className="text-red-500 ml-2">*Required</span>
    </label>
  )
}

export default GuidelineSelect
