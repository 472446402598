import React from 'react'
import { tableStyles } from '../../../utilities/stylesHelpers'

const TableHeader = ({ content, style }) => {
  return (
    <th className={style ? style : tableStyles.th}>
      <div className={tableStyles.thContent}>
        {content}
      </div>
    </th>
  )
}

export default TableHeader
