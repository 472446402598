import React, { useMemo } from "react"
import StaticContent from "./content/staticContent"
import { useDocumentBuilderContext } from "../../contexts/documentBuilderContext"

const Content = ({ contentId }) => {
  const { contents } = useDocumentBuilderContext()
  const content = useMemo(() => contents[contentId], [contentId, contents])
  return <StaticContent contentId={ contentId } content={ content }/>
}

export default Content
