import React, { useReducer } from "react"
import ChecklistCreationModal from "../checklistModal/checklistCreationModal"

const NewChecklistButton = ({ projectId, checklistSlug }) => {
  const [show, toggleShow] = useReducer(showState => !showState, false)

  return (<>
    <button
      className="btn btn-primary font-semibold relative cursor-pointer z-10 w-full"
      id="add-permit-row-btn"
      name="add-permit-row-btn"
      onClick={toggleShow}
    >
      Create a new Checklist
    </button>
    <ChecklistCreationModal projectId={projectId} checklistSlug={checklistSlug} show={show} onClose={toggleShow} />
  </>
  )
}

export default NewChecklistButton
