import React, { useMemo } from 'react'
import { useDocumentBuilderContext } from "../../../contexts/documentBuilderContext"

const DefinitionInput = ({ definition: { id, propertyName, propertyType } }) => {
  const { documentDefinitions } = useDocumentBuilderContext()

  const documentDefinition = useMemo(() => documentDefinitions[id], [documentDefinitions, id])

  return (
    <label
      htmlFor={ `definition-${ id }` }
      className="w-full flex justify-end items-center gap-4"
    >
      { propertyName }
      <input
        type="text"
        name={ `definition-${ id }` }
        className="w-1/2"
        placeholder={ propertyType }
        defaultValue={ documentDefinition?.editedValue || documentDefinition?.dataSourceValue || null }
        disabled={true}
      />
    </label>
  )
}

export default DefinitionInput
