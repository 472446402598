import axios from "axios"
import { notifyError } from "../components/shared/notice"

const csrfToken = document.querySelector('[name=csrf-token]').content

const returnInput = input => input

const rejectError = (error) => {
  console.error(error)
  notifyError('Error: ' + error.message)
  return Promise.reject(error)
}

const API = axios.create({})

API.interceptors.response.use(
  returnInput,
  rejectError,
  { synchronous: true }
)

export default API

export const simpleGet = (path, options = {}) => {
  return API
    .get(path, options)
    .then(res => res.data)
}

export const simplePost = (path, body, options = {}) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .post(path, body, options)
    .then(res => res.data)
}

export const simplePut = (path, body, options = {}) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .put(path, body, options)
    .then(res => res.data)
}

export const simplePatch = (path, body, options = {}) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .patch(path, body, options)
    .then(res => res.data)
}
