import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import Select from "react-select"
import { optionize } from "../../../utilities/utils"
import { useDocumentBuilderApiContext } from "../../../contexts/documentBuilderContext"

const Dropdown = ({ contentId, content }) => {
  const { updateContent } = useDocumentBuilderApiContext()
  const [currentOption, setCurrentOption] = useState({})
  const isDefaultSetRef = useRef(false)

  const options = useMemo(() => optionize('value')(content.options), [content])
  const onChangeHandler = useCallback((event) => setCurrentOption(event), [])

  useEffect(() => {
    if (isDefaultSetRef.current) return
    setCurrentOption(options.find(option => option.value === content.documentContent) || options[0])
    isDefaultSetRef.current = true
  }, [content, options])

  useEffect(() => updateContent(contentId, 'documentContent', currentOption.value), [updateContent, contentId, currentOption])

  return (
    <Select
      value={ currentOption }
      options={ options }
      onChange={ onChangeHandler }
    />
  )
}

export default Dropdown
