import React from 'react'
import { tableStyles } from '../../../utilities/stylesHelpers'
import TableHeader from './tableHeader'

const GuidelineRow = ({ guideline }) => {
  const guidelineString = guideline.name + '\n' + 'DEPTH:' + guideline.analytic_export_guidelines[0].start_depth +
    ' - ' + guideline.analytic_export_guidelines[0].end_depth
  const blanks = Array.from({ length: 7 }, (_, i) => i)
  return (
    <tr className={tableStyles.tableHeaderRow}>
      {blanks.map((index) => (
        <TableHeader key={`${index}-blank`} content={''} />
      ))}
      <TableHeader key={`guideline-name`} content={guidelineString} />
      {guideline.limits.map((limit, index) => (
        <TableHeader key={`limit-${index}}`} content={limit?.string_value || ''} />
      ))}
    </tr>)
}

export default GuidelineRow
