export const formatAdditionalFields = fields => Object.entries(fields).map(([key, value]) => [key, value])

export const createDataCollectionArray = (projectId, isNotesPage, portfolioType, noteLabel, docBuilderTemplates) => {
  const dataCollectionArray = []
  if (portfolioType === 'Assessment') {
    dataCollectionArray.push({
      label: 'Historical Assessment',
      value: `/projects/${ projectId }/historical-assessment`
    })
  }
  if (!isNotesPage) {
    dataCollectionArray.push({ label: noteLabel, value: `/projects/${ projectId }/notes` })
    dataCollectionArray.push({ label: 'Photo Gallery', value: `/projects/${ projectId }/photo_gallery` })
  }
  if (docBuilderTemplates) {
    dataCollectionArray.push({ label: 'Document Log', value: `/projects/${ projectId }/document-log` })
  }

  return dataCollectionArray.slice().sort((a, b) => a.label.localeCompare(b.label))
}
