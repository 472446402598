import React, { useState } from 'react'
import Modal from "../../shared/modal"
import { useAiDocumentAPI, useAiDocumentContext } from '../../../contexts/aiDocumentContext'

const EditModal = () => {
  const { modals } = useAiDocumentContext()
  const { toggleModal, updateJson } = useAiDocumentAPI()
  const [file, setFile] = useState()
  const [filename, setFilename] = useState()

  const handleFileChange = (event) => {
    setFilename(event.target.files[0].name)
    const formData = new FormData()
    formData.append('json_file', event.target.files[0], event.target.files[0].name)
    setFile(formData)
  }

  const onSave = () => {
    updateJson(file)
    toggleModal('json')
  }

  return (
    <>
      { modals.json && (
        <Modal
          heading={ 'AI Document' }
          closeModal={ () => toggleModal('json') }
          onSave={ onSave }
        >
          <div className="m-4 z-50 flex flex-row gap-x-4 justify-between items-center sticky left-4">
            <label className="h-min btn btn-primary btn-outline font-semibold relative cursor-pointer z-10">
              Upload File
              <input type="file" onChange={ handleFileChange } accept=".json" required
                     className="opacity-0 absolute inset-0 z-0 cursor-pointer top-full h-px"/>
            </label>
            { filename && <p>{ filename }</p> }
          </div>
        </Modal>
      ) }
    </>
  )
}

export default EditModal
