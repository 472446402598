import React, { useState } from 'react'
import Select from "react-select"
import Modal from "../../shared/modal"
import { useAiDocumentAPI, useAiDocumentContext } from '../../../contexts/aiDocumentContext'

const StatusModal = () => {
  const { statuses, modals } = useAiDocumentContext()
  const [selectedStatus, setSelectedStatus] = useState('')
  const { toggleModal, updateStatus } = useAiDocumentAPI()
  const statusOptions = statuses.map(status => ({ value: status, label: status }))
  const onSelect = (e) => setSelectedStatus(e?.value)
  const onSave = () => {
    updateStatus(selectedStatus)
    toggleModal('status')
  }

  return (
    <>
      {modals.status && (
        <Modal
          heading={'AI Document'}
          closeModal={() => toggleModal('status')}
          onSave={onSave}
        >
          <div className="m-4 z-50 flex flex-row gap-x-4 sticky left-4">
            <Select
              options={statusOptions}
              onChange={onSelect}
              className='w-1/2, z-50'
              isClearable='true'
            />
          </div>
        </Modal>
      )}
    </>
  )
}

export default StatusModal
