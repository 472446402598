import React, { useMemo } from "react"
import { tableStyles } from "../../../utilities/stylesHelpers"
import { useTimesheetContext } from "../../../contexts/timesheetContext"
import { useTimeTableApi } from "../../../contexts/timeTableContext"

const TimesheetTableRow = ({ entry }) => {
  const { sunday, monday, tuesday, wednesday, thursday, friday, saturday } = entry
  const { tasks, projects } = useTimesheetContext()
  const { initEditModal } = useTimeTableApi()

  const task = useMemo(() => tasks.find(task => task.id === entry.timesheet_task_id), [tasks, entry])
  const project = useMemo(() => projects[entry.project_id], [projects, entry])

  return (
    <tr onClick={() => initEditModal({ ...entry, portfolio_id: project.portfolio_id })}>
      <td className={ tableStyles.td }>
        <p className={ tableStyles.tdText }>{ project.job_number }</p>
      </td>
      <td className={ tableStyles.td }>
        <p className={ tableStyles.tdText }>{ project.historical_project_number }</p>
      </td>
      <td className={ tableStyles.td }>
        <p className={ tableStyles.tdText }>{ project.name }</p>
      </td>
      <td className={ tableStyles.td }>
        <p className={ tableStyles.tdText }>{ task.name }</p>
      </td>
      <td className={ tableStyles.td }>{ sunday }</td>
      <td className={ tableStyles.td }>{ monday }</td>
      <td className={ tableStyles.td }>{ tuesday }</td>
      <td className={ tableStyles.td }>{ wednesday }</td>
      <td className={ tableStyles.td }>{ thursday }</td>
      <td className={ tableStyles.td }>{ friday }</td>
      <td className={ tableStyles.td }>{ saturday }</td>
      <td className={ tableStyles.td }>
        <p className={ tableStyles.tdText }>{ entry.summary }</p>
      </td>
    </tr>
  )
}

export default TimesheetTableRow
