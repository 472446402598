import React, { useMemo } from "react"
import { componentSwitch } from "../../utilities/documentBuilderHelpers"

const Section = ({ section }) => {
  const currentLayout = useMemo(() => {
    return section.sectionLayouts.find(layout => layout.id === section.currentSectionLayout)
  }, [section])

  const SectionLayoutComponent = useMemo(() => componentSwitch(currentLayout?.type), [currentLayout])

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between w-full">
        <h2 className="text-2xl font-bold">{ section.title }</h2>
        <p>{ currentLayout?.name }</p>
      </div>
      { currentLayout && <SectionLayoutComponent sectionLayout={ currentLayout }/> }
    </div>
  )
}

export default Section
