import React, { useMemo } from "react"
import { tableStyles } from "../../../utilities/stylesHelpers"
import { classNames } from "../../../utilities/utils"
import { useTimeTableContext } from "../../../contexts/timeTableContext"
import { weeklyTotals } from "../../../utilities/timesheetHelpers"

const textStyles = classNames(tableStyles.td, "font-bold")

const TimesheetTableTotalRow = () => {
  const { currentWeeksEntries } = useTimeTableContext()

  const {
    sundayTotal,
    mondayTotal,
    tuesdayTotal,
    wednesdayTotal,
    thursdayTotal,
    fridayTotal,
    saturdayTotal,
    total
  } = useMemo(() => weeklyTotals(currentWeeksEntries), [currentWeeksEntries])

  return <tr>
    <td className={ tableStyles.td } colSpan="4">
      <p className={ classNames(tableStyles.tdText, "font-bold") }>Weekly Totals:</p>
    </td>
    <td className={ textStyles }>{ sundayTotal }</td>
    <td className={ textStyles }>{ mondayTotal }</td>
    <td className={ textStyles }>{ tuesdayTotal }</td>
    <td className={ textStyles }>{ wednesdayTotal }</td>
    <td className={ textStyles }>{ thursdayTotal }</td>
    <td className={ textStyles }>{ fridayTotal }</td>
    <td className={ textStyles }>{ saturdayTotal }</td>

    <td className={ textStyles }>Total: { total }</td>
  </tr>
}

export default TimesheetTableTotalRow
