import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { TestResultsProvider } from '../../../contexts/testResultsContext'
import Widget from '../../shared/widget'
import LabBreadCrumbs from '../labBreadCrumbs'
import LayoutTable from './layoutTable'

const TestResults = ({ project, routes }) => {
  const [widgetUrl, setWidgetUrl] = useState('')
  const { analyticExportId } = useParams()
  return (
    <TestResultsProvider>
      <div className="px-4 w-full h-full">
        <LabBreadCrumbs project={project} routes={routes} extension={`analytic-exports/${analyticExportId}`} />
        <div className='w-full h-full flex flex-row'>
          <div className="flex flex-col pr-4 pl-4 h-full flex-shrink flex-grow">
            <div className='grid grid-cols-[auto,1fr] mx-3 my-3'>
              <a href={widgetUrl} target="_blank" rel="noreferrer noopener" className="w-full btn btn-primary font-semibold cursor-pointer block text-center lg:rounded-t-md lg:rounded-b-none"
                style={{ writingMode: 'vertical-rl', transform: 'rotate(180deg)' }}>
                Open Map
              </a>
              <Widget widgetProp={project?.default_widget?.full_url} project={project} widgetStyles='lg:p-0 w-full h-[calc(100%-37px)]' widgetChangeCallback={setWidgetUrl} vertical={true} />
            </div>
          </div>
          <div className='flex-grow px-2 py-4'>
            <div className={`w-full px-2 py-4`}>
              <h2 className="text-2xl font-bold">PROJECT: {project?.name}</h2>
            </div>
            <LayoutTable />
          </div>
        </div>
      </div>
    </TestResultsProvider>
  )
}

export default TestResults
