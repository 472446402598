import React from 'react'
import ActionsSidebar from './actionsSidebar'
import ChatBot from './index'
import { deleteOpenaiObjects } from '../../../../api/openaiApi'
import { useAiChatbotApi } from '../../../../contexts/aiChatbotContext'
import { useParams } from 'react-router-dom'
import { useAiDocumentAPI, useAiDocumentContext } from '../../../../contexts/aiDocumentContext'
import LoadingSpinner from '../../../shared/loadingSpinner'
import { notifyAlert } from "../../../shared/notice"

const ChatBotView = () => {
  const { loading } = useAiDocumentContext()
  const { updateLoading } = useAiDocumentAPI()
  const { projectId } = useParams()
  const { resetMessages } = useAiChatbotApi()
  const actions = [
    {
      action: () => {
        updateLoading(true)
        deleteOpenaiObjects(projectId)
          .then(res => {
            resetMessages()
            updateLoading(false)
            notifyAlert(res.message)
          })
      },
      name: "Reset Chat"
    }
  ]

  return (
    <div className="h-full w-full flex">
      {!loading && <ActionsSidebar actions={ actions }/> }
      {!loading && <ChatBot />}
      {loading && <div className="h-full w-full center"><LoadingSpinner /></div>}
    </div>
  )
}

export default ChatBotView
