import React from 'react'
import Select from "react-select"
import { isEmpty } from "lodash"
import { useAiDocumentFilterContext, useAiDocumentFilterAPI } from '../../../contexts/aiDocumentFilterContext'

const DocumentFilters = () => {
  const {documentOptions, valueOptions, currentDocumentFilter} = useAiDocumentFilterContext()
  const { updateDocumentOptionFilter, updateValueFilter } = useAiDocumentFilterAPI()

  const onFilterSelect = (e) => { updateDocumentOptionFilter(e?.value) }
  const onColumnSelect = (e) => { updateValueFilter(e?.value) }

  return (
    <div className="m-4 z-50 flex flex-row gap-x-4 sticky left-4">
      <Select
        options={documentOptions}
        onChange={onFilterSelect}
        className='w-1/2, z-50'
        isClearable='true'
        placeholder={'Choose a Filter Option'}
      />
      <Select
        options={valueOptions}
        onChange={onColumnSelect}
        className='w-1/2 z-50'
        isClearable
        isDisabled={isEmpty(currentDocumentFilter)}
        placeholder={'Choose Value to Filter for'}
      />
    </div>
  )
}

export default DocumentFilters
