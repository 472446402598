import React from 'react'
import { AiDocumentProvider } from '../../../contexts/aiDocumentContext'
import { AiDocumentFilterProvider } from '../../../contexts/aiDocumentFilterContext'
import DocumentGrid from './documentGrid'
import DocumentModal from './documentModal'
import StatusModal from './statusModal'
import EditModal from "./editModal"

const AiDocuments = () => {
  return (
    <AiDocumentFilterProvider>
      <AiDocumentProvider>
        <div className="py-4 px-8">
          <h1 className="text-3xl font-bold">AI DOCUMENTS</h1>
          <p className="text-lg">Documents larger than 15mb will not be able to be summarized</p>
          <DocumentGrid />
          <DocumentModal />
          <EditModal />
          <StatusModal />
        </div>
      </AiDocumentProvider>
    </AiDocumentFilterProvider>
  )
}

export default AiDocuments
