import React from 'react'
import { AiDocumentProvider } from '../../../contexts/aiDocumentContext'
import { AiChatbotProvider } from '../../../contexts/aiChatbotContext'

import ProjectDocumentsView from './projectDocumentsView'

const AiProjectDocument = (routes) => {

  return (
    <AiChatbotProvider>
      <AiDocumentProvider>
        <ProjectDocumentsView routes={routes} />
      </AiDocumentProvider>
    </AiChatbotProvider>
  )
}

export default AiProjectDocument
